<template>
  <div class="interpreter">
    <img
      src="https://cdn.bubbleplan.cn/static/marketplace/Frame9617.png"
      alt=""
      class="interpreter-back"
      @click="back()"
    />
    <div class="interpreter-bgw">
      <div class="interpreter-bgw-text">
        <div>
          玩家在这里可以参与疯狂派科学家的疯狂科学计划并一定程度上的将芯片转化为晶体。
        </div>
        <div>
          晶体作为人类科技的前沿发现，有着精妙的结构和非凡的能量传递方式，能够与生物体的代谢和感知过程相互交融。在遗忘之地所找到的晶体，绝不仅仅是静态的物质，它们几乎具备智慧，能够触发或阻碍生物的特殊反应，甚至调整生物的神经传导模式。是Bubble
          Plan之中不可或缺的一部分。
        </div>
      </div>
      <div class="interpreter-bgw-rule flex" @click="interpreter()">
        <img
          src="https://cdn.bubbleplan.cn/m_bubble/home/Group10555.png"
          alt=""
          class="interpreter-bgw-rule-img"
        />
        <div class="interpreter-bgw-rule-text">规则介绍</div>
      </div>
      <div class="interpreter-bgw-rule right flex" @click="interpreter()">
        <img
          src="https://cdn.bubbleplan.cn/m_bubble/home/Group10555.png"
          alt=""
          class="interpreter-bgw-rule-img"
        />
        <div class="interpreter-bgw-rule-text">规则介绍</div>
      </div>
      <div class="interpreter-bgw-num flex">
        <div class="interpreter-bgw-num-left flex">
          <div class="interpreter-bgw-num-left-title">获得晶体数量</div>
          <div class="interpreter-bgw-num-left-right flex">
            <img
              src="https://cdn.bubbleplan.cn/m_bubble/home/481.png"
              mode=""
              class="interpreter-bgw-num-left-right-img"
            />
            <div
              class="interpreter-bgw-num-left-right-text"
              v-if="activityData[0]"
            >
              {{ activityData[0].produce }}
            </div>
          </div>
        </div>
        <div class="interpreter-bgw-num-left rightw flex">
          <div class="interpreter-bgw-num-left-title">投入芯片数量</div>
          <div class="interpreter-bgw-num-left-right flex">
            <img
              src="https://cdn.bubbleplan.cn/m_bubble/home/02407201544351.png"
              mode=""
              class="interpreter-bgw-num-left-right-img1"
            />
            <div
              class="interpreter-bgw-num-left-right-text"
              v-if="activityData[0]"
            >
              {{ activityData[0].consume }}
            </div>
          </div>
        </div>
      </div>
      <div class="interpreter-bgw-num rightbox flex">
        <div class="interpreter-bgw-num-left flex">
          <div class="interpreter-bgw-num-left-title">获得晶体数量</div>
          <div class="interpreter-bgw-num-left-right flex">
            <img
              src="https://cdn.bubbleplan.cn/m_bubble/home/481.png"
              mode=""
              class="interpreter-bgw-num-left-right-img"
            />
            <div
              class="interpreter-bgw-num-left-right-text"
              v-if="activityData[1]"
            >
              {{ activityData[1].produce }}
            </div>
          </div>
        </div>
        <div class="interpreter-bgw-num-left rightw flex">
          <div class="interpreter-bgw-num-left-title">投入芯片数量</div>
          <div class="interpreter-bgw-num-left-right flex">
            <img
              src="https://cdn.bubbleplan.cn/m_bubble/home/02407201544351.png"
              mode=""
              class="interpreter-bgw-num-left-right-img1"
            />
            <div
              class="interpreter-bgw-num-left-right-text"
              v-if="activityData[1]"
            >
              {{ activityData[1].consume }}
            </div>
          </div>
        </div>
      </div>
      <div class="interpreter-bgw-button" @click="doPledge(0)">
        <div class="interpreter-bgw-button-title">转换</div>
        <div class="flex" style="justify-content: center; margin-top: 2px">
          <div class="interpreter-bgw-button-text" v-if="activityData[0]">
            消耗 {{ activityData[0].putIn }}
          </div>
          <img
            src="https://cdn.bubbleplan.cn/m_bubble/home/02407201544351.png"
            mode=""
            class="interpreter-bgw-button-img"
          />
        </div>
      </div>
      <div class="interpreter-bgw-button buttonRight" @click="doPledge(1)">
        <div class="interpreter-bgw-button-title">转换</div>
        <div class="flex" style="justify-content: center; margin-top: 2px">
          <div class="interpreter-bgw-button-text" v-if="activityData[1]">
            消耗 {{ activityData[1].putIn }}
          </div>
          <img
            src="https://cdn.bubbleplan.cn/m_bubble/home/02407201544351.png"
            mode=""
            class="interpreter-bgw-button-img"
          />
        </div>
      </div>
      <el-dialog
        title=""
        :visible.sync="show"
        width="30%"
        :before-close="handleClose"
        :show-close="false"
      >
        <div class="addition">
          <img
            src="https://cdn.bubbleplan.cn/m_bubble/marketplace/X2x.png"
            mode=""
            class="addition-img"
            @click="handleClose()"
          />
          <img
            src="https://cdn.bubbleplan.cn/m_bubble/home/1231231231.png"
            mode=""
            class="addition-bacw"
          />
          <div class="addition-num" v-if="activityData[type]">
            ×{{ activityData[type].putIn ** 1 * (value / 10) }}
          </div>
          <div class="addition-progressbar flex">
            <img
              src="https://cdn.bubbleplan.cn/m_bubble/home/add.png"
              mode=""
              class="addition-progressbar-img right19"
              style=""
              @click="subtract"
            />
            <el-slider
              v-model="value"
              :show-tooltip="false"
              height="7"
              :step="10"
              :min="10"
              :max="100"
            ></el-slider>
            <img
              src="https://cdn.bubbleplan.cn/m_bubble/home/reduce.png"
              mode=""
              class="addition-progressbar-img left14"
              style=""
              @click="addnum"
            />
          </div>
          <div class="addition-button" @click="putinto">投入</div>
        </div>
      </el-dialog>
      <el-dialog
        title=""
        :visible.sync="regulationShow"
        width="60%"
        :show-close="false"
      >
        <div class="regulationShowsBgc">
          <div class="regulationShowsBgc-title">
            <img
              src="http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/static/drawalotteryorraffle/X.png"
              alt=""
              class="regulationShowsBgc-x"
              @click="regulationShow = false"
            />质押规则
          </div>
          <div class="regulationShowsBgc-text">
            <div>
              用户可以质押数量为100或2000的芯片，单次质押周期最小值为一个月。
            </div>
            <div class="regulationShowsBgc-text-top">
              在质押时间到期后将获得质押数量的芯片，以及一定数量的质押奖励晶体。
            </div>
            <div class="regulationShowsBgc-text-top">
              单账号的实验室转化机有限。小型转化机10个，大型转化机10个。
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      regulationShow: false,
      show: false,
      value: 10,
      activityData: [],
      type: null,
    };
  },
  mounted() {
    this.listAllPledge();
    this.userNum();
  },
  methods: {
    //查询积分金币
    async userNum() {
      const { data, code } = await this.$ajax.userNum();
      if (code == 200) {
        this.userProperty(data);
      }
    },
    back() {
      history.back();
    },
    interpreter() {
      this.regulationShow = true;
    },
    //投入
    async putinto() {
      const params = {
        pledgeId: this.activityData[this.type].id,
        num: this.value / 10,
      };
      const { data, code } = await this.$ajax.doPledge(params);
      this.handleClose();
      this.listAllPledge();
      this.userNum();
    },
    subtract() {
      if (this.value == 10) {
        this.value = 10;
      } else {
        this.value -= 10;
      }
    },
    addnum() {
      if (this.value == 100) {
        this.value = 100;
      } else {
        this.value += 10;
      }
    },
    doPledge(type) {
      this.type = type;
      this.show = true;
    },
    async listAllPledge() {
      const { data, code } = await this.$ajax.listAllPledge();
      this.activityData = data;
    },
    handleClose() {
      this.show = false;
      this.value = 10;
    },
  },
};
</script>

<style lang="scss" scoped>
.interpreter {
  width: 100%;
  height: calc(100vh - 94px);
  background: url("https://cdn.bubbleplan.cn/static/home/interpreter_bgc.png")
    no-repeat;
  background-size: 100% 100%;
  padding-top: 40px;
  overflow: auto;
  &-back:hover {
    cursor: pointer;
  }
  &-back {
    position: absolute;
    top: 163px;
    left: 90px;
    width: 72px;
    height: 72px;
  }
  &-bgw {
    width: 1032px;
    height: 726px;
    background: url("https://cdn.bubbleplan.cn/static/home/interpreter_ww23.png")
      no-repeat;
    background-size: 100% 100%;
    margin: auto;
    position: relative;
    &-text {
      position: absolute;
      width: 963px;
      height: 100px;
      top: 36px;
      left: 30px;
      font-size: 17px;
      color: #f1eae2;
      line-height: 24px;
    }
    .right {
      left: 555px;
    }
    &-rule :hover {
      cursor: pointer;
    }
    &-rule {
      position: absolute;
      top: 228px;
      left: 39px;
      align-items: center;
      &-img {
        width: 21px;
        height: 21px;
        margin-right: 5.3px;
      }
      &-text {
        color: #f1eae2;
        font-family: "TsangerYuMo";
        font-size: 18px;
        font-weight: bold;
      }
    }
    .rightbox {
      left: 530px;
      top: 538px;
    }
    &-num {
      padding: 0 30px;
      justify-content: space-between;
      position: absolute;
      top: 540px;
      .rightw {
        margin-left: 12px;
      }
      &-left {
        width: 214px;
        height: 32px;
        // border-radius: 50px;
        // border: 1rpx solid #FFF;
        // background: #5E46A7;
        // box-shadow: 0 3rpx 4rpx 0 #00000040 inset;
        padding: 0 10px;
        align-items: center;
        justify-content: space-between;

        &-title {
          color: #ffffff;
          font-size: 18px;
        }

        &-right {
          align-items: center;

          &-img {
            width: 19px;
            height: 22px;
            margin-right: 3px;
          }
          &-img1 {
            width: 24px;
            height: 20px;
          }

          &-text {
            color: #ffffff;
            font-size: 18px;
          }
        }
      }
    }
    .buttonRight {
      left: 690px;
    }
    &-button :hover {
      cursor: pointer;
    }
    &-button {
      width: 189px;
      height: 91px;
      background: url("https://cdn.bubbleplan.cn/m_bubble/home/Group10561.png")
        no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: 158px;
      top: 620px;
      padding-top: 8px;
      &-title {
        color: #fbf9f8;
        text-align: center;
        font-family: "TsangerYuMo";
        font-size: 27px;
        font-weight: bold;
        text-shadow: -2px -2px 0 #af7112, 2px -2px 0 #af7112, -2px 2px 0 #af7112,
          2px 2px 0 #af7112;
        /* 模拟描边效果 */
      }

      &-text {
        color: #925800;
        font-size: 18px;
        font-weight: Bold;
      }

      &-img {
        width: 24px;
        height: 21px;
        margin-left: 18px;
      }
    }
    :deep(.el-dialog) {
      background: transparent;
    }
    .addition {
      width: 628px;
      height: 376px;
      background: url("https://cdn.bubbleplan.cn/m_bubble/home/Group10580.png")
        no-repeat;
      background-size: 100% 100%;
      position: relative;
      padding: 0 60px;
      padding-top: 69px;
      text-align: center;
      align-items: center;
      .right19 {
        margin-right: 19px;
      }
      .left14 {
        margin-left: 14px;
      }
      &-img:hover {
        cursor: pointer;
      }
      &-img {
        width: 67px;
        height: 66px;
        position: absolute;
        right: -22px;
        top: -20px;
      }

      &-bacw {
        margin: auto;
        width: 176px;
        height: 176px;
      }

      &-num {
        color: #ffffff;
        font-family: "TsangerYuMo";
        font-size: 32px;
        font-weight: bold;
        text-shadow: -2px -2px 0 #000000, 2px -2px 0 #000000, -2px 2px 0 #000000,
          2px 2px 0 #000000;
        /* 模拟描边效果 */
        margin-top: -40px;
      }

      &-progressbar {
        align-items: center;
        &-img:hover {
          cursor: pointer;
        }
        &-img {
          width: 43px;
          height: 47px;
        }
        :deep(.el-slider) {
          width: 100%;
        }
        :deep(.el-slider__bar) {
          background-color: #fff;
        }
        :deep(.el-slider__button) {
          width: 33px !important;
          height: 49px !important;
          background: url("https://cdn.bubbleplan.cn/m_bubble/home/Vector740.png")
            no-repeat;
          background-size: 100% 100%;
          background-color: transparent !important;
          margin-top: -6px !important;
          border: none !important;
        }
      }
      &-button:hover {
        cursor: pointer;
      }
      &-button {
        width: 194px;
        height: 80px;
        background: url("https://cdn.bubbleplan.cn/m_bubble/home/Vector594.png")
          no-repeat;
        background-size: 100% 100%;
        margin: auto;
        margin-top: 10px;
        color: #ffffff;
        text-align: center;
        line-height: 80px;
        font-family: "TsangerYuMo";
        font-size: 30px;
        font-weight: bold;
        text-shadow: -2px -2px 0 #000000, 2px -2px 0 #000000, -2px 2px 0 #000000,
          2px 2px 0 #000000;
        /* 模拟描边效果 */
      }
    }
    :deep(.el-dialog) {
      margin-top: 10vh !important;
    }
    .regulationShowsBgc {
      width: 1186px;
      height: 745px;
      background: url("https://cdn.bubbleplan.cn/static/drawalotteryorraffle/Group9870.png")
        no-repeat;
      background-size: 100% 100%;
      position: relative;
      padding-top: 94px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 78px 124px 103.34px 168px;
      &-x {
        cursor: pointer;
      }
      &-x {
        position: absolute;
        top: 63.96px;
        right: 36.76px;
        width: 80.88px;
        height: 79.56px;
      }
      &-title {
        width: 100%;
        color: #574352;
        font-size: 48px;
        font-weight: 900;
        text-align: center;
      }
      &-text::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
      &-text {
        flex: 1;
        overflow: auto;
        margin-top: 40px;
        color: #574352;
        font-size: 24px;
        line-height: 40.8px;
        // background: #d6c4ba;
        padding: 45px 50px;
        &-top {
          margin-top: 30px;
        }
      }
    }
  }
}
</style>